import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { Box, Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { SendGroupMessagePopover } from './components/SendGroupMessagePopover';
import { styles } from './styles';

export const DedicatedChatGroupMessageButton: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const readOnly = useAppSelector(getIsReadonlySelector);

  const handleButtonPress = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box sx={styles.container}>
      <LightTooltip
        title={t`Press to send group message`}
        arrow
        placement="top"
      >
        <Button
          disabled={!!readOnly}
          onClick={handleButtonPress}
          sx={styles.groupMessageButton}
          variant="outlined"
        >
          {t`Group message`}
        </Button>
      </LightTooltip>
      <Popover
        id={id}
        open={open}
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        PaperProps={{ sx: styles.popup }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: 'left',
        }}
        style={{
          transform: 'translateX(-50px)',
        }}
      >
        {open && <SendGroupMessagePopover onClosePopover={handleClose} />}
      </Popover>
    </Box>
  );
};
