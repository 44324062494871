import { useMemo } from 'react';
import { UNASSIGNED_TASKS } from 'constants/common';
import {
  useShipmentsByResourceIdQuery,
  useTerminalGroupedErrandsByResourceIdQuery,
  useUnassignedShipmentsQuery,
} from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getUnassignedPages,
  getUnassignedShipments,
} from 'store/selectors/order';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';
import { ShipmentStatuses } from 'types/api';
import { getOrders } from 'utils/orderUtils';

const bannedStatuses = [ShipmentStatuses.DECLINED, ShipmentStatuses.SHREDDED];

export const useOrdersList = (page?: number) => {
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const unassignedShipments = useAppSelector(getUnassignedShipments);
  const unassignedPages = useAppSelector(getUnassignedPages);
  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);

  const {
    data: shipmentsList,
    refetch: selectedResourceRefetch,
    isFetching: shipmentsFetching,
  } = useShipmentsByResourceIdQuery(
    {
      resourceId: selectedResourceId as string,
      workshiftId: selectedWorkshiftId as string,
    },
    {
      skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS,
    },
  );

  const { data: errandsList, isFetching: errandsFetching } =
    useTerminalGroupedErrandsByResourceIdQuery(
      {
        resourceId: selectedResourceId as string,
        workshiftId: selectedWorkshiftId as string,
      },
      {
        skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS,
      },
    );

  const { isFetching: unassignedIsFetching, refetch: unassignedRefetch } =
    useUnassignedShipmentsQuery(page as number, {
      skip:
        selectedResourceId !== UNASSIGNED_TASKS ||
        !page ||
        (unassignedPages > 0 && page > unassignedPages),
      // refetchOnMountOrArgChange: true,
    });

  const ordersList = useMemo(() => {
    if (selectedResourceId === UNASSIGNED_TASKS) {
      return unassignedShipments
        ? getOrders(unassignedShipments, [], true).filter((s) => s !== null)
        : undefined;
    }

    const filteredErrandsList = errandsList?.filter(
      ({ status }) => !bannedStatuses.includes(status as ShipmentStatuses),
    );

    return shipmentsList
      ? getOrders(shipmentsList, filteredErrandsList).filter((s) => s !== null)
      : undefined;
  }, [shipmentsList, unassignedShipments, selectedResourceId, errandsList]);

  const commonisFetching = useMemo(() => {
    if (shipmentsFetching || errandsFetching) {
      return true;
    }

    return false;
  }, [shipmentsFetching, errandsFetching]);

  return {
    ordersList,
    unassignedIsFetching,
    isFetching: commonisFetching,
    unassignedRefetch,
    refetch: selectedResourceRefetch,
  };
};
