import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cookies from 'browser-cookies';
import { LOCAL_STORAGE_KEYS, LOGGED_IN_COOKIE } from 'constants/common';

interface AuthState {
  managerId: string | null;
  email: string | null;
  name: string | null;
  isAdmin: boolean | null;
  isReadonly: boolean | null;
  loggedIn: string | null;
}

const localIsAdmin = localStorage.getItem(LOCAL_STORAGE_KEYS.adminSection);
const localIsReadonly = localStorage.getItem(LOCAL_STORAGE_KEYS.readOnly);

const initialState: AuthState = {
  managerId: null,
  email: null,
  name: null,
  isReadonly: localIsReadonly === 'true',
  isAdmin:
    localIsAdmin === 'true' ? true : localIsAdmin === 'false' ? false : null,
  loggedIn: cookies.get(LOGGED_IN_COOKIE),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload }: PayloadAction<{ id: string; email: string; name: string }>,
    ) => {
      const { id, email, name } = payload;
      state.managerId = id;
      state.name = name;
      state.email = email;
    },
    setIsLoggedIn: (state) => {
      state.loggedIn = 'true';
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
      if (action.payload) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.adminSection, 'true');
      } else {
        localStorage.setItem(LOCAL_STORAGE_KEYS.adminSection, 'false');
      }
    },
    setIsReadonly: (state, action: PayloadAction<boolean>) => {
      state.isReadonly = action.payload;
      if (action.payload) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.readOnly, 'true');
      } else {
        localStorage.setItem(LOCAL_STORAGE_KEYS.readOnly, 'false');
      }
    },
    logout: () => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.adminSection);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.readOnly);

      return {
        ...initialState,
        isAdmin: null,
        loggedIn: null,
        isReadonly: null,
      };
    },
  },
});

export const {
  setCredentials,
  setIsLoggedIn,
  logout,
  setIsAdmin,
  setIsReadonly,
} = authSlice.actions;

export const authReducer = authSlice.reducer;
