import React, { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useCompanyImagesQuery } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';
import { CompanyImage } from 'types/api';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldAutocomplete } from 'components/common/FieldAutocomplete';

export interface CompanyImageOptions extends CompanyImage {
  label: string;
  value: string;
}
export const OrderDetailsTabPackageLogoSection: FC = () => {
  const { data } = useCompanyImagesQuery();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const companyImageOptions: CompanyImageOptions[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((logoItem) => ({
      ...logoItem,
      label: logoItem.company_name,
      value: logoItem.img_id,
    }));
  }, [data]);

  return (
    <ChangeScreenSection label={t`Package logo`}>
      <Grid container columns={1} rowGap={4}>
        <Grid item mobile={1}>
          <Field
            component={FieldAutocomplete}
            options={companyImageOptions}
            name="packageLogo"
            label={t`Package logo`}
            placeholder={t`Search`}
            disabled={!!readOnly}
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
