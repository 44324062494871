import { FC } from 'react';
import { createFilterOptions, Grid } from '@mui/material';
import {
  GOODS_TYPE_OPTIONS,
  PARCEL_TYPE_OPTIONS,
} from 'constants/editOrderOptions';
import { Field } from 'formik';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';
import { GoodsTypeOption, ParcelTypeOption } from 'types/changeScreenTypes';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldAutocomplete } from 'components/common/FieldAutocomplete';
import { FieldInput } from 'components/common/FieldInput';

const filterGoodsType = createFilterOptions<GoodsTypeOption>();
const filterParcelType = createFilterOptions<ParcelTypeOption>();

export const OrderDetailsTabAdditionalDataSection: FC = () => {
  const readOnly = useAppSelector(getIsReadonlySelector);

  return (
    <ChangeScreenSection label={t`Additional data`}>
      <Grid container columns={1} rowGap={4}>
        <Grid item mobile={1}>
          <Field
            component={FieldAutocomplete}
            options={GOODS_TYPE_OPTIONS || null}
            name="goodsType"
            label={t`Goods type`}
            placeholder={t`Enter goods type`}
            creatableFilterFunction={filterGoodsType}
            fullWidth
            disabled={!!readOnly}
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldAutocomplete}
            options={PARCEL_TYPE_OPTIONS || null}
            name="generalParcelType"
            label={t`General parcel type`}
            placeholder={t`Enter general parcel type`}
            creatableFilterFunction={filterParcelType}
            fullWidth
            disabled={!!readOnly}
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldInput}
            name="goodsMarking"
            label={t`Goods marking`}
            placeholder={t`Enter goods marking`}
            fullWidth
            autoComplete="off"
            disabled={!!readOnly}
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldInput}
            name="palletLocation"
            label={t`Pallet location`}
            placeholder={t`Enter pallet location`}
            fullWidth
            autoComplete="off"
            disabled={!!readOnly}
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
