import React, { FC, memo, useMemo } from 'react';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box, Typography } from '@mui/material';
import { ReactComponent as BankIDLogo } from 'assets/icons/bankIDLogo.svg';
import { ReactComponent as CheckBoxOnIcon } from 'assets/icons/checkBoxOnIcon.svg';
import { ReactComponent as DriverSignOffIcon } from 'assets/icons/driverSignOffIcon.svg';
import { ReactComponent as GlueIcon } from 'assets/icons/glueIcon.svg';
import { ReactComponent as IBoxenIcon } from 'assets/icons/iBoxenIcon.svg';
import { ReactComponent as InsideTheBoxIcon } from 'assets/icons/insideTheBoxIcon.svg';
import { ReactComponent as LeaveOutsideIcon } from 'assets/icons/leaveOutsideIcon.svg';
import { ReactComponent as NobodyHomeIcon } from 'assets/icons/nobodyHomeIcon.svg';
import { ReactComponent as QLocxIcon } from 'assets/icons/qLocxIcon.svg';
import { ReactComponent as SignIcon } from 'assets/icons/sign_icon.svg';
import { useProofTypeName } from 'hooks/useProofTypeName';
import { t } from 'ttag';
import { ErrandDataProofTypes } from 'types/api';
import { StylesProps } from 'types/common';
import { Order, OrderTypes } from 'types/orders';
import { addHyphen } from 'utils/stringUtils';

import { InnerSectionListItem } from 'components/common/InnerSectionListItem';
import { ParcelDataImagesList } from 'components/screens/HomeScreen/components/DetailsSectionInnerParcelDataItem/components/ParcelDataImagesList';
import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';
interface DetailsSectionInnerPickupOrDeliveryExecutionProps {
  order: Order;
}

export const DetailsSectionInnerPickupOrDeliveryExecution: FC<DetailsSectionInnerPickupOrDeliveryExecutionProps> =
  memo(({ order }) => {
    const isNobodyAvailable = order?.proofData?.data?.nobody_available_to_sign;
    const surname = order?.proofData?.data?.surname;
    const firstName = order?.proofData?.data?.firstName;
    const bankIdName = order?.proofData?.data?.name;
    const name = order?.data?.recipientName;
    const proxyName = order?.proofData?.data?.name;
    const proxyDigits = order?.proofData?.data?.proxy_person_digits;
    const lastDigits = order?.proofData?.data?.digits;
    const clientPersonalId = addHyphen(order?.proofData?.data?.personal_number);
    const proxyPersonalId = addHyphen(
      order?.proofData?.data?.proxy_personal_number,
    );
    const proofType = order?.proofData?.proof_type;
    const personalNumber = addHyphen(order?.proofData?.data?.personal_number);
    // const proxyPersonalNumber = order?.proofData?.data?.proxy_personal_number;

    const { proofTypeName } = useProofTypeName(
      proofType as ErrandDataProofTypes,
    );

    const signUrl = order?.proofData?.data?.signature_url;
    const sectionTitle =
      order.type === OrderTypes.DROP ? 'Delivery' : 'Pick up';

    const photoUrls = order.proofData?.data?.order_execution_photo_urls;
    const photosItem = !!photoUrls?.length && (
      <ParcelDataImagesList imageUrls={photoUrls} />
    );

    const comment = order.proofData?.data?.order_execution_comment;
    const commentItem = comment && (
      <Typography sx={styles.commentItem}>{comment}</Typography>
    );

    const ageControl = order?.data?.ageControl;
    const ageControlItem = ageControl && (
      <>
        <InnerSectionListItem
          icon={<WarningAmberOutlinedIcon />}
          iconColor="custom.red2"
          label={t`Age control`}
          value={t`${ageControl} years old`}
        />
        <Box sx={styles.separator} />
      </>
    );

    const context = useMemo(() => {
      if (isNobodyAvailable) {
        return (
          <>
            <InnerSectionListItem
              border={false}
              variant="h4"
              icon={<NobodyHomeIcon />}
              iconColor="custom.green"
              value={t`Nobody available to sign`}
            />
            <Box sx={styles.separator} />
            {photosItem}
            {commentItem}
          </>
        );
      }
      if (proofType === 'in_front_of_door') {
        return (
          <>
            <InnerSectionListItem
              border={false}
              variant="h4"
              icon={<LeaveOutsideIcon />}
              value={t`Leave outside the door`}
            />
            <Box sx={styles.separator} />
            {!!name && <Typography variant="h5">{name}</Typography>}
            <Box sx={styles.separator} />
            {photosItem}
          </>
        );
      }
      if (proofType === 'driver_sign_off') {
        return (
          <>
            <InnerSectionListItem
              border={false}
              icon={<DriverSignOffIcon />}
              value={t`Driver's sign-off`}
            />
            <Box sx={styles.separator} />
            {photosItem}
            {commentItem}
          </>
        );
      }
      if (proofType === 'signature') {
        return (
          <>
            <InnerSectionListItem
              border={false}
              icon={<SignIcon style={{ height: '24px', width: '24px' }} />}
              value={t`Sign-off with signature`}
            />
            <Box sx={styles.separator} />
            {ageControlItem}
            <Box sx={styles.signName}>
              {!!firstName && <Typography variant="h5">{firstName}</Typography>}
              {!!surname && (
                <Typography variant="h5" sx={{ ml: '8px', mb: '16px' }}>
                  {surname}
                </Typography>
              )}
            </Box>
            {!!signUrl && (
              <Box sx={styles.signPhotoBlock}>
                <img
                  height="160px"
                  width="100%"
                  src={signUrl}
                  alt="Signature photo"
                  style={{ objectFit: 'contain' }}
                />
              </Box>
            )}
          </>
        );
      }

      if (
        proofType === 'bankid' ||
        proofType === 'bankid_strict' ||
        proofType === 'bankid_personal_number_strict'
      ) {
        return (
          <>
            <InnerSectionListItem
              border={false}
              customIcon
              icon={<BankIDLogo width="40px" height="40px" />}
              value={proofTypeName}
            />
            <Box sx={styles.separator} />
            {ageControlItem}
            <Box sx={styles.signName}>
              {!!bankIdName && (
                <Typography variant="h5">{bankIdName}</Typography>
              )}
            </Box>
            <Box sx={styles.separator} />
            {personalNumber && (
              <Box sx={styles.digitsBlock}>
                <Typography variant="h5">{personalNumber}</Typography>
                <Typography sx={styles.digits}>{t`Personal number`}</Typography>
              </Box>
            )}
            {clientPersonalId && (
              <>
                <Box sx={styles.separator} />
                <Box sx={styles.digitsBlock}>
                  <Typography variant="h5">{clientPersonalId}</Typography>
                  <Typography
                    sx={styles.digits}
                  >{t`Client Personal ID`}</Typography>
                </Box>
              </>
            )}
          </>
        );
      }

      if (proofType === 'smartlock_inside_the_box') {
        return (
          <InnerSectionListItem
            border={false}
            customIcon
            icon={<InsideTheBoxIcon width="40px" height="40px" />}
            value={t`InsideTheBox delivery`}
          />
        );
      }

      if (proofType === 'smartlock_iboxen') {
        return (
          <InnerSectionListItem
            style={{ paddingTop: 0 } as StylesProps}
            border={false}
            customIcon
            icon={<IBoxenIcon width="40px" height="40px" />}
            value={t`IBoxen delivery`}
          />
        );
      }

      if (proofType === 'smartlock_glue') {
        return (
          <InnerSectionListItem
            border={false}
            customIcon
            icon={<GlueIcon width="40px" height="40px" />}
            value={t`Glue delivery`}
          />
        );
      }

      if (proofType === 'smartlock_qlocx') {
        return (
          <InnerSectionListItem
            border={false}
            customIcon
            icon={<QLocxIcon width="40px" height="40px" />}
            value={t`Qlocx delivery`}
          />
        );
      }

      if (proofType === 'identification_control') {
        return (
          <>
            <InnerSectionListItem
              border={false}
              icon={<PersonOutlineOutlinedIcon />}
              iconColor="custom.blueLight2"
              value={t`ID Confirmation`}
            />
            <Box sx={styles.separator} />
            {ageControlItem}
            <Box sx={styles.signName}>
              {!!name && <Typography variant="h5">{name}</Typography>}
            </Box>
            {clientPersonalId && (
              <>
                <Box sx={styles.separator} />
                <Box sx={styles.digitsBlock}>
                  <Typography variant="h5">{clientPersonalId}</Typography>
                  <Typography
                    sx={styles.digits}
                  >{t`Client Personal ID`}</Typography>
                </Box>
              </>
            )}
            {proxyDigits && proxyDigits.length && (
              <>
                <Box sx={styles.separator} />

                <Box sx={styles.signName}>
                  <CheckBoxOnIcon />
                  <Typography sx={styles.proxy}>{t`Proxy Person`}</Typography>
                </Box>
                <Box sx={styles.signName}>
                  {!!proxyName && (
                    <Typography variant="h5">{proxyName}</Typography>
                  )}
                </Box>
                <Box sx={styles.separator} />
                <Box sx={styles.digitsBlock}>
                  <Typography variant="h5">{proxyDigits.join('')}</Typography>
                  <Typography
                    sx={styles.digits}
                  >{t`Last two digits`}</Typography>
                </Box>
              </>
            )}

            {proxyName && (
              <>
                <Box sx={styles.separator} />
                <Box sx={styles.signName}>
                  <Typography variant="h5">{proxyName}</Typography>
                </Box>
              </>
            )}

            {proxyPersonalId && (
              <>
                <Box sx={styles.separator} />
                <Box sx={styles.digitsBlock}>
                  <Typography variant="h5">{proxyPersonalId}</Typography>
                  <Typography
                    sx={styles.digits}
                  >{t`Proxy Personal ID`}</Typography>
                </Box>
              </>
            )}
          </>
        );
      }
      if (proofType === 'ID_signature' || proofType === 'ID_signature_strict') {
        return (
          <>
            <InnerSectionListItem
              border={false}
              icon={<FactCheckOutlinedIcon />}
              iconColor="custom.blueLight2"
              value={proofTypeName}
            />
            <Box sx={styles.separator} />
            {ageControlItem}
            <Box sx={styles.signName}>
              {!!name && <Typography variant="h5">{name}</Typography>}
            </Box>
            {clientPersonalId && (
              <>
                <Box sx={styles.separator} />
                <Box sx={styles.digitsBlock}>
                  <Typography variant="h5">{clientPersonalId}</Typography>
                  <Typography
                    sx={styles.digits}
                  >{t`Client Personal ID`}</Typography>
                </Box>
              </>
            )}
            {proxyName && (
              <>
                <Box sx={styles.separator} />
                <Box sx={styles.signName}>
                  <Typography variant="h5">{proxyName}</Typography>
                </Box>
              </>
            )}
            {proxyDigits && proxyDigits.length && (
              <>
                <Box sx={styles.separator} />

                <Box sx={styles.signName}>
                  <CheckBoxOnIcon />
                  <Typography sx={styles.proxy}>{t`Proxy Person`}</Typography>
                </Box>
                <Box sx={styles.signName}>
                  {!!proxyName && (
                    <Typography variant="h5">{proxyName}</Typography>
                  )}
                </Box>
                <Box sx={styles.separator} />
                <Box sx={styles.digitsBlock}>
                  <Typography variant="h5">{proxyDigits.join('')}</Typography>
                  <Typography
                    sx={styles.digits}
                  >{t`Last two digits`}</Typography>
                </Box>
              </>
            )}
            {proxyPersonalId && (
              <>
                <Box sx={styles.separator} />
                <Box sx={styles.digitsBlock}>
                  <Typography variant="h5">{proxyPersonalId}</Typography>
                  <Typography
                    sx={styles.digits}
                  >{t`Proxy Personal ID`}</Typography>
                </Box>
              </>
            )}
            {!!signUrl && (
              <>
                <Box sx={styles.separator} />

                <Box sx={styles.signPhotoBlock}>
                  <img
                    height="160px"
                    src={signUrl}
                    alt="Signature photo"
                    style={{ objectFit: 'cover' }}
                  />
                </Box>
              </>
            )}
          </>
        );
      }
    }, [
      isNobodyAvailable,
      photosItem,
      commentItem,
      ageControlItem,
      firstName,
      signUrl,
      surname,
      proofType,
      proxyName,
      proxyDigits,
      name,
      bankIdName,
      lastDigits,
    ]);

    return (
      <InnerSection
        label={t`${sectionTitle} execution`}
        sx={styles.container}
        customStyle={{ pr: '0px' }}
      >
        {context}
      </InnerSection>
    );
  });
