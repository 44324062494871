import { FC, useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useEditScreenErrandTypes } from 'hooks/useChangeScreenErrandTypes';
import { useAllHubsQuery } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';

import { AddressSearch } from 'components/common/AddressSearch';
import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldAutocomplete } from 'components/common/FieldAutocomplete';
import { FieldInput } from 'components/common/FieldInput';

export const PickUpDataTabAddressSection: FC = () => {
  const { formik } = useChangeScreenContext();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const { values, setValues } = formik;

  const { data: hubs } = useAllHubsQuery();
  const { terminalPickType } = useEditScreenErrandTypes();

  const terminalList = useMemo(() => {
    if (!hubs) {
      return [];
    }
    return hubs.map(({ id, hub_name, hub_code }) => ({
      value: id,
      label: `${hub_code} ${hub_name}`,
    }));
  }, [hubs]);

  useEffect(() => {
    if (!values.pickUpTerminalId && terminalPickType) {
      setValues({
        ...values,
        pickAddressId: null,
        pickCity: null,
        pickCountry: null,
        pickLatitude: null,
        pickLongitude: null,
        pickPostalCode: null,
        pickStreet: null,
        pickStreetNumber: null,
        pickState: null,
        pickTerminalName: null,
        pickPhoneNumber: null,
        pickAddressLine2: null,
      });
    }
  }, []);

  const handleAutocompleteChange = useCallback(
    (e: any, val: { value: string; label: string }) => {
      if (val?.value) {
        const currentTerminal = hubs?.find(({ id }) => id === val?.value);

        if (currentTerminal) {
          setValues({
            ...values,
            pickUpTerminalId: val.value,
            pickAddressId: currentTerminal?.address_id,
            pickCity: currentTerminal?.data?.hub_city,
            pickCountry: currentTerminal?.data?.country,
            pickLatitude: currentTerminal?.latitude,
            pickLongitude: currentTerminal?.longitude,
            pickPostalCode: currentTerminal?.data?.hub_ZIP,
            pickStreet: currentTerminal?.data?.hub_street,
            pickStreetNumber: currentTerminal?.data?.hub_street_no,
            pickTerminalName: currentTerminal?.hub_name,
            pickPhoneNumber: currentTerminal?.data?.hub_phone,
            pickAddressLine2: currentTerminal?.data?.hub_adr_line_2,
            pickState: null,
          });
        } else {
          setValues({
            ...values,
            pickAddressId: null,
            pickCity: null,
            pickCountry: null,
            pickLatitude: null,
            pickLongitude: null,
            pickPostalCode: null,
            pickStreet: null,
            pickStreetNumber: null,
            pickState: null,
            pickTerminalName: null,
            pickPhoneNumber: null,
            pickAddressLine2: null,
          });
        }
      }
    },
    [values, setValues, hubs],
  );

  return (
    <ChangeScreenSection label={t`Address`}>
      <Grid container columns={12} spacing={2} rowGap={4}>
        <Grid item mobile={6}>
          {terminalPickType ? (
            <Field
              component={FieldAutocomplete}
              options={terminalList}
              name="pickUpTerminalId"
              label={t`Terminal name`}
              onChange={handleAutocompleteChange}
              placeholder={t`Enter terminal name or code`}
              disabled={!!readOnly}
            />
          ) : (
            <AddressSearch formik={formik} type="pick" />
          )}
        </Grid>
        <Grid item mobile={4}>
          <Field
            component={FieldInput}
            name="pickStreet"
            label={t`Street`}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mobile={2}>
          <Field
            component={FieldInput}
            name="pickStreetNumber"
            label={t`Street number`}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mobile={6}>
          <Field
            component={FieldInput}
            name="pickAddressId"
            label={t`Address ID`}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mobile={2}>
          <Field
            component={FieldInput}
            name="pickPostalCode"
            label={t`Postal code`}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mobile={4}>
          <Field
            component={FieldInput}
            name="pickCity"
            label={t`City`}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mobile={3}>
          <Field
            component={FieldInput}
            name="pickLatitude"
            label={t`Latitude`}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mobile={3}>
          <Field
            component={FieldInput}
            name="pickLongitude"
            label={t`Longitude`}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mobile={6}>
          <Field
            component={FieldInput}
            name="pickState"
            label={t`State`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={6}>
          <Field
            component={FieldInput}
            name="pickAddressLine2"
            label={t`Address line 2`}
            disabled={terminalPickType || !!readOnly}
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={6}>
          <Field
            component={FieldInput}
            name="pickCountry"
            label={t`Country`}
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
