import React, { FC, useCallback, useMemo, useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { IconButton, Typography } from '@mui/material';
import {
  useCompanyByIdQuery,
  useEditCompanyByIdMutation,
} from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { getSelectedCompanyIdSelector } from 'store/selectors/company';
import { t } from 'ttag';

import { DialogModal } from 'components/ui/DialogModal';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const DisableCompanyButton: FC = React.memo(() => {
  const selectedCompanyId = useAppSelector(getSelectedCompanyIdSelector);
  const { data: companyDetail } = useCompanyByIdQuery(
    selectedCompanyId as string,
    {
      skip: !selectedCompanyId,
    },
  );

  const [openModal, setOpenModal] = useState(false);
  const [editCompany, { isLoading }] = useEditCompanyByIdMutation();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const disabled = !companyDetail?.is_active;

  const handleModalConfirm = useCallback(() => {
    editCompany({
      id: companyDetail?.id,
      is_active: !companyDetail?.is_active,
    });
  }, [companyDetail?.id, companyDetail?.is_active]);

  const { name, city, country } = useMemo(
    () => ({
      name: companyDetail?.name || '',
      city: companyDetail?.city || '',
      country: companyDetail?.country || '',
    }),
    [companyDetail],
  );

  return (
    <>
      <LightTooltip
        title={
          disabled
            ? t`You can change company status to enable`
            : t`You can change company status to disable`
        }
        arrow
        placement="top"
      >
        <span>
          <IconButton
            sx={styles.button}
            onClick={() => setOpenModal(true)}
            disabled={isLoading || !!readOnly}
          >
            {disabled ? (
              <BusinessIcon sx={{ color: 'custom.graphite' }} />
            ) : (
              <DomainDisabledIcon sx={{ color: 'custom.graphite' }} />
            )}
            <Typography variant="body2" ml="8px" alignSelf="flex-end">
              {disabled ? t`Enable company` : t`Disable company`}
            </Typography>
          </IconButton>
        </span>
      </LightTooltip>

      <DialogModal
        open={openModal}
        onConfirm={handleModalConfirm}
        onClose={() => setOpenModal(false)}
        title={
          !disabled
            ? t`Are you sure you want to disable ${name}, ${city}, ${country} Company?`
            : t`Are you sure you want to enable ${name}, ${city}, ${country} Company?`
        }
        content={
          !disabled
            ? t`All devices connected to this company will be disabled`
            : t`All devices connected to this company will be enabled`
        }
      />
    </>
  );
});
