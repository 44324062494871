import { FC } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useEditScreenErrandTypes } from 'hooks/useChangeScreenErrandTypes';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldInput } from 'components/common/FieldInput';
import { Switch } from 'components/ui/Switch';

import { styles } from './styles';

export const PickUpDataTabDetailsSection: FC = () => {
  const { formik } = useChangeScreenContext();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const { values, handleChange } = formik;
  const { terminalPickType } = useEditScreenErrandTypes();

  const getContent = () => (
    <Grid container columns={1} spacing={2} rowGap={2}>
      {terminalPickType ? (
        <Grid item mobile={1}>
          <Field
            component={FieldInput}
            name="pickCageCode"
            label={t`Cage code`}
            placeholder={t`Enter cage code`}
            autoComplete="off"
            disabled={!!readOnly}
            // required
          />
        </Grid>
      ) : (
        <>
          <Grid item mobile={1}>
            <Field
              component={FieldInput}
              name="pickPersonalNumber"
              label={t`Personal number`}
              placeholder={t`Enter personal number`}
              fullWidth
              autoComplete="new-password"
              disabled={!!readOnly}
            />
          </Grid>
          <Grid item mobile={1}>
            <Field
              component={FieldInput}
              name="pickEnterCode"
              label={t`Door code`}
              placeholder={t`Enter door code`}
              autoComplete="off"
              disabled={!!readOnly}
            />
          </Grid>
          <Grid item mobile={1}>
            <Field
              component={FieldInput}
              name="pickIntercomCode"
              label={t`Intercom code`}
              placeholder={t`Enter intercom code`}
              autoComplete="off"
              disabled={!!readOnly}
            />
          </Grid>
          <Grid item mobile={1}>
            <Field
              component={FieldInput}
              name="pickFloorNumber"
              label={t`Floor number`}
              placeholder={t`Enter floor number`}
              fullWidth
              autoComplete="new-password"
              disabled={!!readOnly}
            />
          </Grid>
          <Grid
            container
            columns={1}
            spacing={2}
            rowGap={1.5}
            sx={{ ml: '8px' }}
          >
            <Grid item mobile={1}>
              <Switch
                name="pickElevator"
                label={t`The house has an elevator`}
                value={!!values.pickElevator}
                checked={!!values.pickElevator}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            </Grid>
            <Grid item mobile={1} sx={styles.switchGridItem}>
              <Switch
                name="pickKnockOnTheDoor"
                label={t`Knock on the door`}
                value={!!values.pickKnockOnTheDoor}
                checked={!!values.pickKnockOnTheDoor}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            </Grid>
            <Grid item mobile={1} sx={styles.switchGridItem}>
              <Switch
                name="pickUseDoorBell"
                label={t`Use doorbell`}
                value={!!values.pickUseDoorBell}
                checked={!!values.pickUseDoorBell}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            </Grid>
            <Grid item mobile={1} sx={styles.switchGridItem}>
              <Switch
                name="pickQuietPickup"
                label={t`Quiet pick up`}
                value={!!values.pickQuietPickup}
                checked={!!values.pickQuietPickup}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            </Grid>
          </Grid>
          {/* <Grid item mobile={1} sx={styles.switchGridItem}>
            <Switch
              name="pickPackagingReturn"
              label={t`Packaging return`}
              value={!!values.pickPackagingReturn}
              checked={!!values.pickPackagingReturn}
              onChange={handleChange}
            />
          </Grid> */}
        </>
      )}
      <Grid item mobile={1} sx={styles.switchGridItem}>
        <Field
          component={FieldInput}
          name="pickInstruction"
          label={t`Pick up instruction`}
          placeholder={t`Enter instruction`}
          multiline
          maxRows={10}
          fullWidth
          disabled={!!readOnly}
        />
      </Grid>
    </Grid>
  );

  return (
    <ChangeScreenSection label={t`Details`}>{getContent()}</ChangeScreenSection>
  );
};
