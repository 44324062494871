import React, { FC } from 'react';
import { ListItem, SxProps, Typography } from '@mui/material';

import { styles } from './styles';

interface SettingsChapterProps {
  isActive: boolean;
  label: string;
  onPress: () => void;
  disabled?: boolean;
}

export const SettingsChapter: FC<SettingsChapterProps> = (props) => {
  const { isActive, label, onPress, disabled = false } = props;

  const activeItemStyles = isActive
    ? ({ ...styles.listItem, ...styles.listItemActive } as SxProps)
    : styles.listItem;

  return (
    <ListItem sx={activeItemStyles}>
      <Typography
        component="a"
        sx={disabled ? styles.disabled : styles.link}
        onClick={disabled ? undefined : onPress}
      >
        <Typography component="span" variant="body2" sx={styles.linkTitle}>
          {label}
        </Typography>
      </Typography>
    </ListItem>
  );
};
