import { FC, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { APP_ROUTES, CHANGE_PAGE_ROUTES } from 'constants/common';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateIsPreviousScreenChat } from 'store/reducers/settingsSlice';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface CreateOrderButtonProps {
  disabled?: boolean;
}
export const CreateOrderButton: FC<CreateOrderButtonProps> = ({
  disabled = false,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const handleCreatePress = useCallback(() => {
    if (pathname.includes('chat')) {
      dispatch(updateIsPreviousScreenChat(true));
    }
  }, [pathname]);

  if (disabled) {
    return (
      <LightTooltip
        title={t`You could set enable create order in resource settings`}
        arrow
        placement="top"
      >
        <span>
          <IconButton
            sx={styles.button}
            disabled={disabled}
            onClick={handleCreatePress}
          >
            <QueryParamsLink
              route={`${APP_ROUTES.create}/${CHANGE_PAGE_ROUTES.orderDetails}`}
            >
              <AddIcon
                sx={styles.icon}
                color={disabled ? 'disabled' : 'primary'}
              />
            </QueryParamsLink>
          </IconButton>
        </span>
      </LightTooltip>
    );
  }

  const iconStyle = useMemo(() => {
    if (readOnly) {
      return {
        ...styles.icon,
        color: 'custom.graphite',
      };
    }

    return { ...styles.icon, color: 'primary.main' };
  }, [readOnly]);

  return (
    <IconButton
      sx={styles.button}
      disabled={!!readOnly}
      onClick={handleCreatePress}
    >
      <QueryParamsLink
        route={`${APP_ROUTES.create}/${CHANGE_PAGE_ROUTES.orderDetails}`}
      >
        <AddIcon sx={iconStyle} />
      </QueryParamsLink>
    </IconButton>
  );
};
