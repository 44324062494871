/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Errand,
  ErrandDataAgeControl,
  ErrandDataProofTypes,
  ErrandServiceTypes,
} from 'types/api';
import { ChangeScreenErrandType } from 'types/changeScreenTypes';

export const getErrandPossibleProofTypes = (
  ageControl: ErrandDataAgeControl | null,
  proofType: ErrandDataProofTypes | null,
) => {
  if (proofType) {
    return ageControl
      ? [proofType, ErrandDataProofTypes.ageControl]
      : [proofType];
  }
  return null;
};

export const isProofTypeSupportsAgeControl = (
  proofType?: ErrandDataProofTypes | null,
) =>
  proofType &&
  (proofType === ErrandDataProofTypes.idControl ||
    proofType === ErrandDataProofTypes.signOff ||
    proofType === ErrandDataProofTypes.idSignature ||
    proofType === ErrandDataProofTypes.idSignatureStrict ||
    proofType === ErrandDataProofTypes.bankId ||
    proofType === ErrandDataProofTypes.bankIdStrictPersonalNumber ||
    proofType === ErrandDataProofTypes.bankIdStrict);

export const getChangeScreenErrandType = (
  errand?: Errand,
): ChangeScreenErrandType | null => {
  if (!errand) {
    return null;
  }

  const {
    data: { company_name: companyName, is_terminal: isTerminal },
    service,
  } = errand;

  if (
    isTerminal ||
    service === 'Terminal pickup' ||
    service === 'Terminal delivery'
  ) {
    return 'terminal';
  }

  return companyName ? 'business' : 'consumer';
};

export const getPickAndDeliveryServiceNames = (
  pickErrandType: ChangeScreenErrandType,
  deliveryErrandType: ChangeScreenErrandType,
): { pickService: ErrandServiceTypes; deliveryService: ErrandServiceTypes } => {
  // TODO: Implement logic for Terminal delivery type
  if (pickErrandType === 'terminal' && deliveryErrandType !== 'terminal') {
    return { pickService: 'Terminal pickup', deliveryService: 'Distribution' };
  }

  if (deliveryErrandType === 'terminal' && pickErrandType !== 'terminal') {
    return {
      pickService: 'Distribution',
      deliveryService: 'Terminal delivery',
    };
  }
  return { pickService: 'Courier', deliveryService: 'Courier' };
};

export const getFullName = (
  firstName: string | null,
  lastName: string | null,
) => {
  return firstName && lastName ? `${firstName} ${lastName}` : null;
};
