import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { Field } from 'formik';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldInput } from 'components/common/FieldInput';

import { ChangeScreenFormProps } from '../../types';

import { styles } from './styles';

export const AdditionsDataTabOtherData: FC<ChangeScreenFormProps> = () => {
  const readOnly = useAppSelector(getIsReadonlySelector);

  return (
    <ChangeScreenSection label={t`Other`}>
      <Grid container columns={1} rowGap={2}>
        <Grid container columns={1} spacing={2} rowGap={4}>
          <Grid item mobile={1} sx={styles.gridItem}>
            <Field
              component={FieldInput}
              name="installation"
              label={t`Installation`}
              placeholder={t`Enter installation time`}
              type="number"
              disabled={!!readOnly}
            />
            <Typography>{t`pcs`}</Typography>
          </Grid>
          <Grid item mobile={1} sx={styles.gridItem}>
            <Field
              component={FieldInput}
              name="ferryAdditionCost"
              label={t`Ferry addition cost`}
              placeholder={t`Enter ferry addition cost`}
              type="number"
              disabled={!!readOnly}
            />
            <Typography>{t`pcs`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
