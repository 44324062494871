import React, { FC, useCallback, useMemo } from 'react';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {
  FormControlLabel,
  Grid,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';
import { ErrandPriorityType } from 'types/api';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';

import { styles } from './styles';

export const AdditionsDataTabSwitchSection: FC = () => {
  const { formik } = useChangeScreenContext();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const { values, handleChange, setFieldValue } = formik;

  const changeAdditionPackageCharge = (
    name: 'smallAdditionPackageCharge' | 'bigAdditionPackageCharge',
  ) => {
    const {
      smallAdditionPackageCharge: smallChargeValue,
      bigAdditionPackageCharge: bigChargeValue,
    } = values;

    if (
      (smallChargeValue === true && name === 'smallAdditionPackageCharge') ||
      (bigChargeValue === true && name === 'bigAdditionPackageCharge')
    ) {
      setFieldValue(name, false);
    } else if (smallChargeValue === bigChargeValue) {
      setFieldValue(name, !values[name]);
    } else {
      setFieldValue('smallAdditionPackageCharge', !smallChargeValue);
      setFieldValue('bigAdditionPackageCharge', !bigChargeValue);
    }
  };

  const handleToggleButtonsChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, val: ErrandPriorityType) => {
      setFieldValue('priority', val);
    },
    [],
  );

  const control = useMemo(() => {
    return {
      name: 'priority',
      value: values.priority,
      onChange: handleToggleButtonsChange,
      exclusive: true,
    };
  }, [values.priority, handleToggleButtonsChange]);

  const prioritiesToggleChildren = [
    <ToggleButton value="-1" key="left" sx={styles.toggleButton}>
      <DoNotDisturbIcon />
    </ToggleButton>,
    <ToggleButton value="0" key="left" sx={styles.toggleButton}>
      <Typography sx={styles.red}>0</Typography>
    </ToggleButton>,
    <ToggleButton value="1" key="center" sx={styles.toggleButton}>
      <Typography sx={styles.orange}>1</Typography>
    </ToggleButton>,
    <ToggleButton value="2" key="right" sx={styles.toggleButton}>
      <Typography sx={styles.purple}>2</Typography>
    </ToggleButton>,
    <ToggleButton value="3" key="justify" sx={styles.toggleButton}>
      <Typography sx={styles.blue}>3</Typography>
    </ToggleButton>,
  ];

  return (
    <ChangeScreenSection variant="standard">
      <Grid container columns={1} rowGap={2}>
        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <ToggleButtonGroup
                // color="primary"
                disabled={!!readOnly}
                size="small"
                {...control}
                aria-label="Small sizes"
                sx={styles.toggleGroup}
              >
                {prioritiesToggleChildren}
              </ToggleButtonGroup>
            }
            label={t`Priority`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="adr"
                id="adr"
                value={!!values.adr}
                checked={!!values.adr}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            }
            label={t`ADR`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="smallAdditionPackageCharge"
                id="smallAdditionPackageCharge"
                value={!!values.smallAdditionPackageCharge}
                checked={!!values.smallAdditionPackageCharge}
                onChange={() => {
                  changeAdditionPackageCharge('smallAdditionPackageCharge');
                }}
                disabled={!!readOnly}
              />
            }
            label={t`Small addition package charge`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="bigAdditionPackageCharge"
                id="bigAdditionPackageCharge"
                value={!!values.bigAdditionPackageCharge}
                checked={!!values.bigAdditionPackageCharge}
                onChange={() => {
                  changeAdditionPackageCharge('bigAdditionPackageCharge');
                }}
                disabled={!!readOnly}
              />
            }
            label={t`Big addition package charge`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="pickNewAddress"
                id="pickNewAddress"
                value={!!values.pickNewAddress}
                checked={!!values.pickNewAddress}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            }
            label={t`New pickup address`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="deliveryNewAddress"
                id="deliveryNewAddress"
                value={!!values.deliveryNewAddress}
                checked={!!values.deliveryNewAddress}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            }
            label={t`New delivery address`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="temperedTransport"
                id="temperedTransport"
                value={!!values.temperedTransport}
                checked={!!values.temperedTransport}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            }
            label={t`Tempered transport`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="onCallCourier"
                id="onCallCourier"
                value={!!values.onCallCourier}
                checked={!!values.onCallCourier}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            }
            label={t`On call courier`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="timeSlottedLorry"
                id="timeSlottedLorry"
                value={!!values.timeSlottedLorry}
                checked={!!values.timeSlottedLorry}
                onChange={handleChange}
                disabled={!!readOnly}
              />
            }
            label={t`Time slotted lorry`}
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
