import { FC, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { FormikProps } from 'formik';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateIsPreviousScreenChat } from 'store/reducers/settingsSlice';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { getIsPreviousScreenChat } from 'store/selectors/settings';
import { t } from 'ttag';

import { ChangeScreenFormValues } from '../ChangeScreenForms/types';

import { styles } from './styles';

interface ChangeScreenMenuProps {
  formik: FormikProps<ChangeScreenFormValues>;
  createPress?: boolean;
}

export const ChangeScreenMenu: FC<ChangeScreenMenuProps> = ({
  formik,
  createPress,
}) => {
  const cancelButtonStyles = { ...styles.button, ...styles.cancelButton };
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const dispatch = useAppDispatch();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const isPreviousScreenChat = useAppSelector(getIsPreviousScreenChat);

  const handleCancelPress = useCallback(() => {
    if (isPreviousScreenChat) {
      navigateWithQueryParams(APP_ROUTES.chat);
    } else {
      navigateWithQueryParams(APP_ROUTES.root);
    }

    dispatch(updateIsPreviousScreenChat(false));
  }, [isPreviousScreenChat, navigateWithQueryParams]);

  return (
    <Box sx={styles.container}>
      <Button
        onClick={handleCancelPress}
        sx={cancelButtonStyles}
        variant="outlined"
      >
        {t`Cancel`}
      </Button>
      <Button
        sx={styles.button}
        disabled={formik.isSubmitting || !!createPress || !!readOnly}
        variant="contained"
        type="submit"
      >
        {t`Save`}
      </Button>
    </Box>
  );
};
