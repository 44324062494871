import React, { memo, useCallback, useEffect, useState } from 'react';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { format } from 'date-fns';
import { Field, Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useCustomBackHandler } from 'hooks/useCustomBackHandker';
import {
  useEditFuelReportByIdMutation,
  useFuelReportByIdQuery,
  useUploadFuelReportImageMutation,
} from 'store/api/adminApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setToast } from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import { FuelTypes } from 'types/fuelReport';

import { FieldInput } from 'components/common/FieldInput';
import { FieldSelect } from 'components/common/FieldSelect';
import { ImagesList } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/ImagesList';
import { UpdatedInfo } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/UpdatedInfo';

import { FuelReportDetailHeader } from '../FuelReportDetailHeader';

import {
  mainFormValidationSchema,
  MainFormValues,
} from './fuelReportDetailValidation';
import { styles } from './styles';

interface FeedbackDetailProps {
  selectedId: string;
  setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
}

type FuelTypeOption = {
  value: FuelTypes;
  label: string;
};
const fuelTypeOptions: FuelTypeOption[] = [
  { value: 'Diesel', label: t`Diesel` },
  { value: 'Electricity', label: t`Electricity` },
  { value: 'Gasoline', label: t`Gasoline` },
  { value: 'Gasoline95', label: t`Gasoline95` },
  { value: 'Gasoline98', label: t`Gasoline98` },
  { value: 'HVO100', label: t`HVO100` },
  { value: 'Gas', label: t`Gas` },
  { value: 'Biodiesel', label: t`Biodiesel` },
];

const defaultValues: MainFormValues = {
  licensePlate: '',
  fuelType: 'Diesel',
  amount: 0,
  odometer: 0,
};

export const FuelReportDetail = memo((props: FeedbackDetailProps) => {
  const { selectedId, setSelectedId } = props;
  const { data } = useFuelReportByIdQuery(
    { reportId: selectedId },
    { refetchOnMountOrArgChange: true },
  );
  const [initialValues, setInitialValues] =
    useState<MainFormValues>(defaultValues);
  const [images, setImages] = useState<string[]>([]);
  const [editFuelReport] = useEditFuelReportByIdMutation();
  const [uploadImage] = useUploadFuelReportImageMutation();

  const dispatch = useAppDispatch();

  const backHandler = useCallback(() => {
    if (selectedId) {
      setSelectedId(null);
      return false;
    }

    return true;
  }, [selectedId, setSelectedId]);

  useCustomBackHandler(backHandler);

  useEffect(() => {
    if (data) {
      setInitialValues({
        licensePlate: data?.licence_plate || '',
        fuelType: data?.fuel_type || 'Diesel',
        amount: data?.amount_fueled || 0,
        odometer: data?.current_odometer || 0,
      });
      setImages(data?.images?.length ? data.images : []);
    }
  }, [data]);

  const handleAddNewImage = useCallback(
    async (imageFile: File) => {
      const formData = new FormData();
      formData.append('file', imageFile); // Replace 'image' with the field name your backend expects

      try {
        const response = await uploadImage(formData).unwrap();
        setImages([...images, response]);
      } catch (err) {
        dispatch(
          setToast({
            message: t`Please select a valid JPG image!`,
            severity: 'error',
          }),
        );
      }
    },
    [images, setImages],
  );

  const onSubmit = useCallback(
    async (
      formValues: MainFormValues,
      { setSubmitting, resetForm }: FormikHelpers<MainFormValues>,
    ) => {
      setSubmitting(true);

      await editFuelReport({
        report_id: selectedId,
        current_odometer: formValues.odometer,
        licence_plate: formValues.licensePlate,
        fuel_type: formValues.fuelType,
        amount_fueled: formValues.amount,
        images,
      });

      setSubmitting(false);
      resetForm();
      setSelectedId(null);
      dispatch(
        setToast({
          message: t`You have successfully updated fuel report`,
          severity: 'success',
        }),
      );
    },
    [setSelectedId, selectedId, images],
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: mainFormValidationSchema,
    onSubmit,
  });

  const handleCancelPress = useCallback(() => {
    setSelectedId(null);
  }, [setSelectedId]);

  const handleLocationPress = useCallback(() => {
    const url = `https://www.google.com/maps?q=${data?.gps_location?.latitude},${data?.gps_location?.longitude}`;
    window.open(url, '_blank');
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <FormControl
        component={Form}
        sx={styles.container}
        noValidate
        autoComplete="off"
      >
        <FuelReportDetailHeader
          licence={data?.licence_plate || ''}
          resourceNumber={data?.resource_number || ''}
          setCurrentFeedbackId={setSelectedId}
        />

        <Stack direction="column" sx={styles.contentBlock}>
          <UpdatedInfo
            date={data?.updated_at || ''}
            driver={data?.updated || ''}
            customStyle={styles.updateInfo}
          />
          <Grid
            container
            columns={6}
            spacing={2}
            rowGap={2}
            padding={2}
            mt="8px"
          >
            <Grid item mobile={2}>
              <TextField
                id="resurceNumber"
                label={t`Resource number`}
                placeholder={t`Resource`}
                value={data?.resource_number || ''}
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>

            <Grid item mobile={2}>
              <TextField
                id="Time"
                label={t`Time`}
                value={
                  data?.created_at
                    ? format(new Date(data.created_at), 'hh:mm aa')
                    : ''
                }
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>
            <Grid item mobile={2}>
              <TextField
                id="Date"
                label={t`Date`}
                value={
                  data?.created_at
                    ? format(new Date(data.created_at), 'dd.MM.yyyy')
                    : ''
                }
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>

            <Grid item mobile={3}>
              <Box sx={styles.gpsBlock}>
                <TextField
                  id="gpsLocation"
                  label={t`GPS - location`}
                  value={`${data?.gps_location?.latitude} - ${data?.gps_location?.longitude}`}
                  fullWidth
                  disabled
                  InputLabelProps={{ shrink: true }}
                  sx={styles.input}
                />
                <IconButton sx={styles.gpsIcon} onClick={handleLocationPress}>
                  <GpsFixedIcon color="primary" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item mobile={3}>
              <Field
                component={FieldInput}
                name="licensePlate"
                label={t`Licence plate`}
                fullWidth
                isTrim
                autoComplete="off"
              />
            </Grid>
            <Grid item mobile={3}>
              <Field
                component={FieldInput}
                name="odometer"
                label={t`Odometer`}
                type="number"
              />
            </Grid>
            <Grid item mobile={3}>
              <Field
                component={FieldSelect}
                name="fuelType"
                label={t`Fuel type`}
                fullWidth
                options={fuelTypeOptions}
                autoComplete="off"
                sx={{ height: '44px' }}
              />
            </Grid>
            <Grid item mobile={3}>
              <TextField
                id="distance"
                label={t`Distance`}
                placeholder={t`Distance`}
                value={data?.distance || 0}
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>
            <Grid item mobile={3}>
              <Field
                component={FieldInput}
                name="amount"
                label={t`Fuel amount`}
                type="number"
              />
            </Grid>
            <Grid item mobile={3}>
              <TextField
                id="driverName"
                label={t`Driver name`}
                placeholder={t`Driver name`}
                value={data?.driver_name || ''}
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>
            <Grid item mobile={3}>
              <TextField
                id="comment"
                label={t`Comment`}
                value={data?.comment || ''}
                fullWidth
                disabled
                multiline
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>

            <Grid item mobile={6}>
              <ImagesList
                images={images}
                maxLength={6}
                setImages={setImages}
                onAddImage={handleAddNewImage}
              />
            </Grid>
          </Grid>

          <Box sx={styles.buttonBlock}>
            <Button
              onClick={handleCancelPress}
              sx={styles.button}
              variant="outlined"
            >
              {t`Cancel`}
            </Button>
            <Button
              variant="contained"
              sx={styles.button}
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {t`Save`}
            </Button>
          </Box>
        </Stack>
      </FormControl>
    </FormikProvider>
  );
});
