import { memo, useCallback, useMemo } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useSetChatPriorityMutation } from 'store/api/chatApiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';
import { ChatPriority } from 'types/dedicatedChat';

import { styles } from './style';

interface ChatPriorityProps {
  work_shift_id: string;
  priority: ChatPriority;
  shipment_id?: string;
}

type PriorityOptions = { label: string; value: string; color: string };

const options: PriorityOptions[] = [
  { label: t`Closed`, value: '0', color: 'custom.green' },
  { label: t`Open`, value: '1', color: 'custom.blue2' },
  { label: t`High priority`, value: '2', color: 'custom.red' },
];

export const ChatPrioritySelect = memo((props: ChatPriorityProps) => {
  const { work_shift_id, priority, shipment_id } = props;
  // const [currentPriority, setCurrentPriority] = useState<string>(
  //   String(priority),
  // );
  const readOnly = useAppSelector(getIsReadonlySelector);

  const [setApiPriority] = useSetChatPriorityMutation();
  const currentOption = useMemo(() => {
    return options.find((option) => option.value === String(priority));
  }, [priority]);

  const menuItems = useMemo(() => {
    return options.map((option) => {
      return (
        <MenuItem value={option.value} key={option.label}>
          <Typography sx={{ ...styles.menuItemContent, color: option.color }}>
            {option.label}
          </Typography>
        </MenuItem>
      );
    });
  }, []);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const { value } = event.target;
      if (value === String(priority)) {
        return;
      }
      setApiPriority({
        work_shift_id,
        shipment_id,
        priority: value,
      });
    },
    [work_shift_id, shipment_id, priority],
  );

  return (
    <FormControl sx={{ m: 1, minWidth: 163, minHeight: 40 }} variant="outlined">
      <Select
        disabled={!!readOnly}
        value={priority === undefined ? '' : String(priority)}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          '&.MuiOutlinedInput-root fieldset': {
            borderColor: currentOption?.color,
          },
          '& .MuiSelect-icon': {
            color: currentOption?.color,
          },
        }}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});
