import { t } from 'ttag';
import {
  ErrandDataAgeControl,
  ErrandDataProofTypes,
  ShipmentStatuses,
} from 'types/api';
import {
  ChangeScreenErrandType,
  GoodsTypeOption,
  NameOfServiceOption,
  ParcelTypeOption,
} from 'types/changeScreenTypes';
import { SelectOption } from 'types/common';

export const ERRAND_SERVICE_TYPE_OPTIONS: SelectOption<ChangeScreenErrandType>[] =
  [
    { value: 'terminal', label: t`Terminal light` },
    { value: 'business', label: t`Detailed` },
    { value: 'consumer', label: t`Light` },
  ];

export const DELIVERY_ERRAND_SERVICE_TYPE_OPTIONS: SelectOption<ChangeScreenErrandType>[] =
  [
    { value: 'terminal', label: t`Terminal light` },
    { value: 'business', label: t`Detailed` },
    { value: 'consumer', label: t`Light` },
  ];

export const STATUS_TYPE_OPTIONS: SelectOption<ShipmentStatuses>[] = [
  {
    label: ShipmentStatuses.ASSIGNED,
    value: ShipmentStatuses.ASSIGNED,
  },
  {
    label: ShipmentStatuses.CONFIRMED,
    value: ShipmentStatuses.CONFIRMED,
  },
  {
    label: ShipmentStatuses.DECLINED,
    value: ShipmentStatuses.DECLINED,
  },
  {
    label: ShipmentStatuses.DROPPED,
    value: ShipmentStatuses.DROPPED,
  },
  {
    label: ShipmentStatuses.FAILED_DROP,
    value: ShipmentStatuses.FAILED_DROP,
  },
  {
    label: ShipmentStatuses.FAILED_PICK,
    value: ShipmentStatuses.FAILED_PICK,
  },
  {
    label: ShipmentStatuses.PICKED,
    value: ShipmentStatuses.PICKED,
  },
  {
    label: ShipmentStatuses.UNASSIGNED,
    value: ShipmentStatuses.UNASSIGNED,
  },
];

export const ERRAND_SERVICE_OPTIONS: NameOfServiceOption[] = [
  { value: 'two_hours_courier', label: t`2 hours Courier` },
  { value: 'three_hours_courier', label: t`3 hours Courier` },
  { value: 'six_hours_courier', label: t`6 hours Courier` },
  { value: 'three_hours_truck', label: t`3 hours Truck` },
  { value: 'six_hours_truck', label: t`6 hours Truck` },
  { value: 'best_distribution', label: t`Best Distribution` },
  { value: 'best_package', label: t`Best Package` },
  { value: 'best_pall', label: t`Best Pall` },
  { value: 'direct_courier', label: t`Direct Courier` },
  { value: 'direct_truck', label: t`Direct Truck` },
  { value: 'distribution', label: t`Distribution` },
  { value: 'fixed_price', label: t`Fixed Price` },
  { value: 'fixed_trip', label: t`Fixed trip` },
  { value: 'home_delivery', label: t`Home delivery` },
  { value: 'home_delivery_evening', label: t`Home delivery Evening` },
  { value: 'it_logistics', label: t`IT Logistics` },
  { value: 'normal_4h_sll', label: t`Normal 4H SLL` },
  { value: 'panic', label: t`Panic` },
  { value: 'postal_collection', label: t`Postal collection` },
  { value: 'mail_delivery', label: t`Mail delivery` },
  { value: 'fast_2h_sll', label: t`Fast 2H SLL` },
  { value: 'terminal_return', label: t`Terminal return` },
  {
    value: 'terminal_return_home_delivery_evening',
    label: t`Terminal return Home delivery Evening`,
  },
];

export const PARCEL_VOLUME_VALUES = {
  box: { length: 0.25, width: 0.2, height: 0.1 },
  cardboardBox: { length: 0.56, width: 0.385, height: 0.35 },
  envelope: { length: 0.3, width: 0.25, height: 0.01 },
  halfPallet: { length: 0.8, width: 0.6, height: 0.5 },
  pallet: { length: 1.2, width: 0.8, height: 1 },
  smallerPackage: { length: 0.1, width: 0.8, height: 0.05 },
};

export const PARCEL_TYPE_OPTIONS: ParcelTypeOption[] = [
  {
    value: 'box',
    label: t`Box`,
    length: PARCEL_VOLUME_VALUES.box.length,
    width: PARCEL_VOLUME_VALUES.box.width,
    height: PARCEL_VOLUME_VALUES.box.height,
    secondaryLabel: `L ${PARCEL_VOLUME_VALUES.box.length} × W ${PARCEL_VOLUME_VALUES.box.width} × H ${PARCEL_VOLUME_VALUES.box.height}`,
  },
  {
    value: 'cardboard box',
    label: t`Cardboard box`,
    length: PARCEL_VOLUME_VALUES.cardboardBox.length,
    width: PARCEL_VOLUME_VALUES.cardboardBox.width,
    height: PARCEL_VOLUME_VALUES.cardboardBox.height,
    secondaryLabel: `L ${PARCEL_VOLUME_VALUES.cardboardBox.length} × W ${PARCEL_VOLUME_VALUES.cardboardBox.width} × H ${PARCEL_VOLUME_VALUES.cardboardBox.height}`,
  },
  {
    value: 'envelope',
    label: t`Envelope`,
    length: PARCEL_VOLUME_VALUES.envelope.length,
    width: PARCEL_VOLUME_VALUES.envelope.width,
    height: PARCEL_VOLUME_VALUES.envelope.height,
    secondaryLabel: `L ${PARCEL_VOLUME_VALUES.envelope.length} × W ${PARCEL_VOLUME_VALUES.envelope.width} × H ${PARCEL_VOLUME_VALUES.envelope.height}`,
  },
  {
    value: 'half pallet',
    label: t`Half pallet`,
    length: PARCEL_VOLUME_VALUES.halfPallet.length,
    width: PARCEL_VOLUME_VALUES.halfPallet.width,
    height: PARCEL_VOLUME_VALUES.halfPallet.height,
    secondaryLabel: `L ${PARCEL_VOLUME_VALUES.halfPallet.length} × W ${PARCEL_VOLUME_VALUES.halfPallet.width} × H ${PARCEL_VOLUME_VALUES.halfPallet.height}`,
  },
  { value: 'mix', label: t`Mix` },
  { value: 'other', label: t`Other` },
  {
    value: 'pallet',
    label: t`Pallet`,
    length: PARCEL_VOLUME_VALUES.pallet.length,
    width: PARCEL_VOLUME_VALUES.pallet.width,
    height: PARCEL_VOLUME_VALUES.pallet.height,
    secondaryLabel: `L ${PARCEL_VOLUME_VALUES.pallet.length} × W ${PARCEL_VOLUME_VALUES.pallet.width} × H ${PARCEL_VOLUME_VALUES.pallet.height}`,
  },
  {
    value: 'smallerPackage',
    label: t`Smaller package`,
    length: PARCEL_VOLUME_VALUES.smallerPackage.length,
    width: PARCEL_VOLUME_VALUES.smallerPackage.width,
    height: PARCEL_VOLUME_VALUES.smallerPackage.height,
    secondaryLabel: `L ${PARCEL_VOLUME_VALUES.smallerPackage.length} × W ${PARCEL_VOLUME_VALUES.smallerPackage.width} × H ${PARCEL_VOLUME_VALUES.smallerPackage.height}`,
  },
];

export const PROOF_TYPE_OPTIONS: SelectOption<ErrandDataProofTypes>[] = [
  { value: ErrandDataProofTypes.signOff, label: t`Signature` },
  { value: ErrandDataProofTypes.driverSignOff, label: t`Driver sign off` },
  { value: ErrandDataProofTypes.idControl, label: t`ID control` },
  { value: ErrandDataProofTypes.bankId, label: t`Bank ID` },
  { value: ErrandDataProofTypes.bankIdStrict, label: t`Bank ID strict` },
  {
    value: ErrandDataProofTypes.bankIdStrictPersonalNumber,
    label: t`Bank ID personal number strict`,
  },
  { value: ErrandDataProofTypes.leaveOutside, label: t`In front of door` },
  { value: ErrandDataProofTypes.idSignature, label: t`ID signature` },
  {
    value: ErrandDataProofTypes.idSignatureStrict,
    label: t`ID signature strict`,
  },
  {
    value: ErrandDataProofTypes.smartLockInsideTheBox,
    label: t`Smart lock inside the box`,
  },
  { value: ErrandDataProofTypes.smartLockGlue, label: t`Smart lock glue` },
  { value: ErrandDataProofTypes.smartLockQlocx, label: t`Smart lock qlocx` },
  { value: ErrandDataProofTypes.smartLockIboxen, label: t`Smart lock iboxen` },
];

export const AGE_CONTROL_OPTIONS: SelectOption<ErrandDataAgeControl>[] = [
  { value: 18, label: t`18 years old` },
  { value: 20, label: t`20 years old` },
];

export const GOODS_TYPE_OPTIONS: GoodsTypeOption[] = [
  { value: 'alcohol', label: t`Alcohol` },
  { value: 'chemical', label: t`Chemical` },
  { value: 'dangerous', label: t`Dangerous` },
  { value: 'electronics', label: t`Electronics` },
  { value: 'fragile', label: t`Fragile` },
  { value: 'general', label: t`General` },
  { value: 'medical', label: t`Medical` },
];
