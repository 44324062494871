import { FC } from 'react';
import { createFilterOptions, Grid, TextField } from '@mui/material';
import {
  DELIVERY_ERRAND_SERVICE_TYPE_OPTIONS,
  ERRAND_SERVICE_OPTIONS,
  ERRAND_SERVICE_TYPE_OPTIONS,
} from 'constants/editOrderOptions';
import { Field } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';
import { NameOfServiceOption } from 'types/changeScreenTypes';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldAutocomplete } from 'components/common/FieldAutocomplete';
import { FieldInput } from 'components/common/FieldInput';

export const OrderDetailsTabOrderDataSection: FC = () => {
  const { formik } = useChangeScreenContext();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const { values, handleChange, errors } = formik;

  const filterNameOfService = createFilterOptions<NameOfServiceOption>();

  return (
    <ChangeScreenSection label={t`Order data`}>
      <Grid container columns={2} spacing={2} rowGap={4}>
        <Grid item mobile={1}>
          <Field
            component={FieldAutocomplete}
            options={ERRAND_SERVICE_TYPE_OPTIONS}
            name="pickUpType"
            label={t`Driver pickup view`}
            placeholder={t`Enter pick up type`}
            required
            disabled={!!readOnly}
          />
        </Grid>
        <Grid item mobile={1}>
          <TextField
            id="numberOfParcels"
            name="numberOfParcels"
            label={t`Number of parcels`}
            value={values.numberOfParcels}
            onChange={handleChange}
            error={!!errors.numberOfParcels}
            helperText={errors.numberOfParcels}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldAutocomplete}
            options={DELIVERY_ERRAND_SERVICE_TYPE_OPTIONS}
            name="deliveryType"
            label={t`Driver delivery view`}
            placeholder={t`Enter delivery type`}
            required
            disabled={!!readOnly}
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldInput}
            name="freightBill"
            label={t`Freight bill`}
            placeholder={t`Enter freight bill`}
            fullWidth
            isTrim
            autoComplete="off"
            disabled={!!readOnly}
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldAutocomplete}
            options={ERRAND_SERVICE_OPTIONS}
            name="nameOfService"
            label={t`Name of service`}
            placeholder={t`Enter name of service`}
            creatableFilterFunction={filterNameOfService}
            required
            disabled={!!readOnly}
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldInput}
            name="bookingNumber"
            label={t`Booking number`}
            placeholder={t`Enter booking number`}
            fullWidth
            isTrim
            required
            autoComplete="off"
            disabled={!!readOnly}
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
