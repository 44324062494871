export const stringAvatar = (name?: string) => {
  if (!name) {
    return '';
  }

  if (name.split(' ').length === 1) {
    return name[0].toUpperCase();
  }

  return `${name.split(' ')?.[0]?.[0]?.toUpperCase()}${name
    .split(' ')?.[1]?.[0]
    ?.toUpperCase()}`;
};

export const addHyphen = (str?: string) => {
  if (!str || str.length !== 12) {
    return str;
  }

  return `${str.slice(0, 8)} - ${str.slice(8)}`;
};
