import { FC, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const CreateCompanyrButton: FC = () => {
  const readOnly = useAppSelector(getIsReadonlySelector);

  const iconStyle = useMemo(() => {
    if (readOnly) {
      return {
        ...styles.icon,
        color: 'custom.graphite',
      };
    }

    return { ...styles.icon, color: 'primary.main' };
  }, [readOnly]);

  return (
    <LightTooltip title={t`Create a new company`} arrow placement="top">
      <span>
        <IconButton disabled={!!readOnly} sx={styles.button}>
          <QueryParamsLink route={APP_ROUTES.createCompany}>
            <AddIcon sx={iconStyle} />
          </QueryParamsLink>
        </IconButton>
      </span>
    </LightTooltip>
  );
};
