import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, FormControl, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { omit, throttle } from 'lodash';
import { useCompanyImagesQuery } from 'store/api/apiSlice';
import { CreateBookingMutation, CreateErrandMutation } from 'store/api/types';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setToast } from 'store/reducers/settingsSlice';
import { getToastInfo } from 'store/selectors/settings';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { BookingPackages, Shipment, ShipmentStatuses } from 'types/api';
import { ChangeScreenFormOnSubmitFunctionParams } from 'types/changeScreenTypes';
import { Order } from 'types/orders';
import {
  getChangeScreenErrandType,
  getErrandPossibleProofTypes,
  getFullName,
  getPickAndDeliveryServiceNames,
} from 'utils/changeScreenUtils';
import { getOldShipmentStatus } from 'utils/orderUtils';
import { getMidnightDate, toISOStringWithTimezone } from 'utils/timeUtils';

import { ChangeScreenMenu } from '../ChangeScreenMenu';
import { ChangeScreenNavigation } from '../ChangeScreenNavigation';

import { styles } from './styles';
import {
  AdditionsDataTabFormValues,
  ChangeScreenFormValues,
  ChangeScreenFormValuesKeys,
  DeliveryDataTabFormValues,
  OrderDetailsTabFormValues,
  ParcelDataFormItem,
  ParcelDataTabFormValues,
  PickUpDataTabFormValues,
} from './types';
import { changeScreenFormValidationSchema } from './validationSchemas';

interface ShowSnackbarProps {
  isShow: boolean;
  errorLength: number;
  isSubmit: boolean;
}
const ShowSnackbar: FC<ShowSnackbarProps> = ({
  isShow,
  errorLength,
  isSubmit,
}) => {
  const toastInfo = useAppSelector(getToastInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isShow && isSubmit) {
      if (!toastInfo) {
        dispatch(
          setToast({
            message: t`${errorLength} form field(s) are filled incorrectly`,
            severity: 'error',
          }),
        );
      }
    }
  }, [isShow, toastInfo, errorLength, isSubmit]);

  return null;
};

interface ChangeScreenFormProps {
  shipment?: Shipment | null;
  // resource?: ResourceApi;
  order?: Order | null;
  onSubmit: (submitParams: ChangeScreenFormOnSubmitFunctionParams) => void;
  label?: string;
  isCreateScreen: boolean;
  createScreenState?: any;
  createPress?: boolean;
}

export const ChangeScreenForms: FC<ChangeScreenFormProps> = ({
  shipment,
  // resource,
  order,
  onSubmit,
  label,
  isCreateScreen,
  createScreenState,
  createPress,
}) => {
  const {
    booking,
    delivery_errand: deliveryErrand,
    pickup_errand: pickupErrand,
  } = shipment || {};

  const currentWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);

  // const setWorkshiftDependsOnResource = useMemo(() => {
  //   if (!resource) {
  //     return '';
  //   }
  //
  //   if (resource?.work_shifts.some(({ id }) => id === currentWorkshiftId)) {
  //     return currentWorkshiftId || '';
  //   }
  //
  //   const currentWorkshift = resource.work_shifts.find(({ start, end }) =>
  //     isCurrentWorkshift(start, end),
  //   );
  //
  //   return currentWorkshift?.id || '';
  // }, [resource, currentWorkshiftId]);

  const { data: companyImageList } = useCompanyImagesQuery();

  const initialStatus = shipment
    ? getOldShipmentStatus(shipment)
    : ShipmentStatuses.ASSIGNED;
  const [pickNameOfSender, pickSurnameOfSender] =
    pickupErrand?.data?.name?.split(/\s(.*)/s) || ['', ''];
  const [deliveryNameOfSender, deliverySurnameOfSender] =
    deliveryErrand?.data?.name?.split(/\s(.*)/s) || ['', ''];

  const orderDetailsTabFormInitialValues: OrderDetailsTabFormValues = {
    pickUpType: getChangeScreenErrandType(pickupErrand) || 'terminal',
    deliveryType: getChangeScreenErrandType(deliveryErrand) || 'consumer',
    nameOfService: pickupErrand?.data.title || null,
    numberOfParcels: booking?.packages.length || 0,
    freightBill: booking?.freight_bill || '',
    bookingNumber: booking?.data?.number || null,

    pickEarliest: new Date(pickupErrand?.earliest_date || getMidnightDate()),
    pickLatest: new Date(pickupErrand?.latest_date || getMidnightDate()),
    pickEta: pickupErrand?.eta ? new Date(pickupErrand?.eta) : null,
    pickProofType: pickupErrand?.data.possible_proof_types?.[0] || null,
    pickAgeControl: pickupErrand?.data.age_control || null,
    pickOrderNumber: pickupErrand?.order_number || null,

    deliveryEarliest: new Date(deliveryErrand?.earliest_date || Date.now()),
    deliveryLatest: new Date(deliveryErrand?.latest_date || Date.now()),
    deliveryEta: deliveryErrand?.eta ? new Date(deliveryErrand?.eta) : null,
    deliveryProofType: deliveryErrand?.data.possible_proof_types?.[0] || null,
    deliveryAgeControl: deliveryErrand?.data.age_control || null,
    deliveryOrderNumber: deliveryErrand?.order_number || null,

    assignedResource: currentWorkshiftId as string,
    status: initialStatus,

    packageLogo: booking?.data?.img_id || null,

    terminalBeLatest: order?.time?.be_terminal_latest || null,
    terminalLeaveLatest: order?.time?.leave_terminal_latest || null,

    goodsType: booking?.data?.goods_type || null,
    generalParcelType: booking?.data?.general_parcel_type || null,
    goodsMarking: booking?.data?.goods_marking || null,
    palletLocation: booking?.data?.pallet_location || null,
  };

  const pickupDataTabFormInitialValues: PickUpDataTabFormValues = {
    pickUpTerminalId: pickupErrand?.data?.terminal_id || null,
    pickAddressId: pickupErrand?.address_id || null,
    pickLatitude: pickupErrand?.address.latitude || null,
    pickLongitude: pickupErrand?.address.longitude || null,
    pickCountry: pickupErrand?.address.country || null,
    pickPostalCode: pickupErrand?.address.postal_code || null,
    pickState: pickupErrand?.address.state || null,
    pickCity: pickupErrand?.address.city || null,
    pickStreet: pickupErrand?.address.street || null,
    pickAddressLine2: pickupErrand?.address?.address_line_2 || null,
    pickStreetNumber: pickupErrand?.address.street_number
      ? pickupErrand?.address.street_number + ''
      : null,

    pickCompanyName: pickupErrand?.data.company_name || null,
    pickTerminalName: pickupErrand?.data.terminal_name || null,
    pickNameOfSender,
    pickSurnameOfSender,
    pickPhoneNumber: pickupErrand?.data.phone || null,

    pickCageCode: pickupErrand?.data.cage || null,
    pickEnterCode: pickupErrand?.data.enter_code || null,
    pickIntercomCode: pickupErrand?.data.intercom || null,
    pickFloorNumber: pickupErrand?.data.floor || null,
    pickElevator: pickupErrand?.data.elevator || null,
    pickKnockOnTheDoor: pickupErrand?.data.knock_on_the_door || null,
    pickPackagingReturn: pickupErrand?.data.packaging_return || null,
    pickUseDoorBell: pickupErrand?.data.use_doorbell || null,
    pickQuietPickup: pickupErrand?.data.quiet_pickup || null,
    pickInstruction: pickupErrand?.data.instruction || null,
    pickPersonalNumber: pickupErrand?.data.personal_number || null,
    pickSearch: '',
  };

  const deliveryDataTabFormInitialValues: DeliveryDataTabFormValues = {
    deliveryTerminalId: deliveryErrand?.data?.terminal_id || null,
    deliveryAddressId: deliveryErrand?.address_id || null,
    deliveryLatitude: deliveryErrand?.address.latitude || null,
    deliveryLongitude: deliveryErrand?.address.longitude || null,
    deliveryCountry: deliveryErrand?.address.country || null,
    deliveryPostalCode: deliveryErrand?.address.postal_code || null,
    deliveryState: deliveryErrand?.address.state || null,
    deliveryCity: deliveryErrand?.address.city || null,
    deliveryStreet: deliveryErrand?.address.street || null,
    deliveryAddressLine2: deliveryErrand?.address?.address_line_2 || null,
    deliveryStreetNumber: deliveryErrand?.address.street_number
      ? deliveryErrand.address.street_number + ''
      : null,

    deliveryCompanyName: deliveryErrand?.data.company_name || null,
    deliveryTerminalName: deliveryErrand?.data.terminal_name || null,
    deliveryNameOfSender,
    deliverySurnameOfSender,
    deliveryPhoneNumber: deliveryErrand?.data.phone || null,

    deliveryCageCode: deliveryErrand?.data.cage || null,
    deliveryEnterCode: deliveryErrand?.data.enter_code || null,
    deliveryIntercomCode: deliveryErrand?.data.intercom || null,
    deliveryFloorNumber: deliveryErrand?.data.floor || null,
    deliveryElevator: deliveryErrand?.data.elevator || null,
    deliveryKnockOnTheDoor: deliveryErrand?.data.knock_on_the_door || null,
    deliveryUseDoorBell: deliveryErrand?.data.use_doorbell || null,
    deliveryQuietDelivery: deliveryErrand?.data.quiet_delivery || null,
    deliveryPackagingReturn: deliveryErrand?.data.packaging_return || null,
    deliveryInstruction: deliveryErrand?.data.instruction || null,
    deliveryPersonalNumber: deliveryErrand?.data.personal_number || null,
    deliverySearch: '',
  };

  const parcelsDataTabFormInitialValues: ParcelDataTabFormValues = {
    parcels: booking?.packages || [],
  };

  const additionsDataFormInitialValues: AdditionsDataTabFormValues = {
    smallAdditionPackageCharge:
      booking?.data?.small_addition_package_charge || null,
    bigAdditionPackageCharge:
      booking?.data?.big_addition_package_charge || null,
    temperedTransport: booking?.data?.tempered_transport || null,
    priority: booking?.data?.priority || '-1',
    extraPalletSpace: booking?.data?.extra_pallet_space || null,
    flatbedMeter: booking?.data?.flatbed_meter || null,
    installation: booking?.data?.installation || null,
    adr: booking?.data?.adr || null,

    deliveryNewAddress: deliveryErrand?.data.new_address || null,
    deliveryUnloadingTime: deliveryErrand?.data.unloading_time || null,
    deliveryWaitingTime: deliveryErrand?.data.waiting_time || null,

    onCallCourier: pickupErrand?.data.on_call_courier || null,
    ferryAdditionCost: pickupErrand?.data.ferry_addition_cost || null,
    pickLoadingTime: pickupErrand?.data.loading_time || null,
    pickNewAddress: pickupErrand?.data.new_address || null,
    pickWaitingTime: pickupErrand?.data.waiting_time || null,
    timeSlottedLorry: pickupErrand?.data.time_slotted_lorry || null,
  };

  const initialValues: ChangeScreenFormValues = {
    ...orderDetailsTabFormInitialValues,
    ...pickupDataTabFormInitialValues,
    ...deliveryDataTabFormInitialValues,
    ...parcelsDataTabFormInitialValues,
    ...additionsDataFormInitialValues,
  };

  const changeScreenFormValuesKeys: ChangeScreenFormValuesKeys = {
    orderDetailsTabFormValuesKeys: Object.keys(
      orderDetailsTabFormInitialValues,
    ),
    pickupDataTabFormValuesKeys: Object.keys(pickupDataTabFormInitialValues),
    deliveryDataTabFormValuesKeys: Object.keys(
      deliveryDataTabFormInitialValues,
    ),
    parcelsDataTabFormValuesKeys: Object.keys(parcelsDataTabFormInitialValues),
    additionsDataTabFormValuesKeys: Object.keys(additionsDataFormInitialValues),
  };

  const submitFormHandler = async (
    {
      pickAgeControl,
      pickCageCode,
      pickCompanyName,
      pickElevator,
      pickQuietPickup,
      pickUseDoorBell,
      pickEnterCode,
      pickFloorNumber,
      pickInstruction,
      pickIntercomCode,
      pickKnockOnTheDoor,
      pickNameOfSender,
      pickSurnameOfSender,
      pickPhoneNumber,
      pickProofType,
      pickEarliest,
      pickEta,
      pickLatest,
      pickOrderNumber,
      pickCity,
      pickCountry,
      pickState,
      pickLongitude,
      pickLatitude,
      pickStreetNumber,
      pickStreet,
      pickAddressId,
      pickPostalCode,
      pickUpType,
      ferryAdditionCost,
      pickLoadingTime,
      pickNewAddress,
      pickAddressLine2,
      pickPersonalNumber,
      // pickPackagingReturn,
      pickTerminalName,
      pickWaitingTime,
      timeSlottedLorry,
      onCallCourier,

      deliveryAgeControl,
      deliveryCageCode,
      deliveryCompanyName,
      deliveryElevator,
      deliveryQuietDelivery,
      deliveryUseDoorBell,
      deliveryEnterCode,
      deliveryFloorNumber,
      deliveryInstruction,
      deliveryIntercomCode,
      deliveryKnockOnTheDoor,
      deliveryNameOfSender,
      deliverySurnameOfSender,
      deliveryPhoneNumber,
      deliveryProofType,
      deliveryEarliest,
      deliveryEta,
      deliveryLatest,
      deliveryOrderNumber,
      deliveryCity,
      deliveryCountry,
      deliveryState,
      deliveryLongitude,
      deliveryLatitude,
      deliveryStreetNumber,
      deliveryStreet,
      deliveryAddressId,
      deliveryPostalCode,
      deliveryType,
      deliveryNewAddress,
      deliveryPackagingReturn,
      deliveryUnloadingTime,
      deliveryWaitingTime,
      deliveryPersonalNumber,

      status: shipmentStatus,
      assignedResource: workShiftId,
      freightBill,
      bookingNumber,
      parcels: packages,
      adr,
      temperedTransport,
      priority,
      smallAdditionPackageCharge,
      bigAdditionPackageCharge,
      extraPalletSpace,
      flatbedMeter,
      installation,
      goodsType,
      generalParcelType,
      goodsMarking,
      palletLocation,
      nameOfService,
      packageLogo,
      pickUpTerminalId,
      deliveryTerminalId,
      deliveryTerminalName,
      deliveryAddressLine2,
    }: ChangeScreenFormValues,
    helpers: FormikHelpers<ChangeScreenFormValues>,
  ) => {
    helpers.setSubmitting(true);
    const pickName = getFullName(
      pickNameOfSender || '',
      pickSurnameOfSender || '',
    );
    const deliveryName = getFullName(
      deliveryNameOfSender || '',
      deliverySurnameOfSender || '',
    );
    const businessPickType = pickUpType === 'business';
    // const businessDeliveryType = deliveryType === 'business';

    const pickProofTypes = getErrandPossibleProofTypes(
      pickAgeControl || null,
      pickProofType || null,
    );
    const deliveryProofTypes = getErrandPossibleProofTypes(
      deliveryAgeControl || null,
      deliveryProofType || null,
    );
    const { pickService, deliveryService } = getPickAndDeliveryServiceNames(
      pickUpType,
      deliveryType,
    );

    const chosenCompanyImage = companyImageList?.find(
      (companyImage) => companyImage.img_id === packageLogo,
    );
    const img_name = chosenCompanyImage?.img_name;
    const img_url = chosenCompanyImage?.img_url;

    const changedPickErrand: CreateErrandMutation = {
      address: {
        city: pickCity,
        country: pickCountry,
        id: pickAddressId,
        latitude: pickLatitude || 0,
        longitude: pickLongitude || 0,
        postal_code: pickPostalCode,
        state: pickState || null,
        street: pickStreet,
        street_number: pickStreetNumber || null,
        // ? parseInt(pickStreetNumber) : null,
        address_line_2: pickAddressLine2 || null,
      },
      address_id: pickAddressId,
      earliest_date: toISOStringWithTimezone(pickEarliest),
      eta: toISOStringWithTimezone(pickEta || new Date()),
      latest_date: toISOStringWithTimezone(pickLatest),
      order_number: pickOrderNumber || null,
      proof: null,
      proof_id: null,
      service: pickService,
      data: {
        age_control: pickAgeControl || null,
        cage: pickCageCode || null,
        company_name: businessPickType
          ? pickCompanyName
            ? pickCompanyName
            : ''
          : '',
        elevator: pickElevator || null,
        enter_code: pickEnterCode || null,
        ferry_addition_cost: ferryAdditionCost || null,
        floor: pickFloorNumber || null,
        instruction: pickInstruction || null,
        intercom: pickIntercomCode || null,
        is_terminal: pickUpType === 'terminal',
        knock_on_the_door: pickKnockOnTheDoor || null,
        use_doorbell: pickUseDoorBell || null,
        quiet_pickup: pickQuietPickup || null,
        loading_time: pickLoadingTime || null,
        unloading_time: null,
        packaging_return: null,
        name: pickName,
        new_address: pickNewAddress || null,
        on_call_courier: onCallCourier || null,
        // packaging_return: pickPackagingReturn,
        phone: pickPhoneNumber || null,
        terminal_name: pickTerminalName || null,
        time_slotted_lorry: timeSlottedLorry || null,
        title: nameOfService || null,
        waiting_time: pickWaitingTime || null,
        possible_proof_types: pickProofTypes,
        terminal_id: pickUpTerminalId || null,
        personal_number: pickPersonalNumber || null,
      },
    };

    const changedDeliveryErrand: CreateErrandMutation = {
      address: {
        city: deliveryCity,
        country: deliveryCountry,
        id: deliveryAddressId,
        latitude: deliveryLatitude || 0,
        longitude: deliveryLongitude || 0,
        postal_code: deliveryPostalCode,
        state: deliveryState || null,
        street: deliveryStreet,
        address_line_2: deliveryAddressLine2 || null,
        street_number: deliveryStreetNumber || null,
        // ? parseInt(deliveryStreetNumber)
        // : null,
      },
      address_id: deliveryAddressId,
      earliest_date: toISOStringWithTimezone(deliveryEarliest),
      eta: deliveryEta ? toISOStringWithTimezone(deliveryEta) : null,
      latest_date: toISOStringWithTimezone(deliveryLatest),
      order_number: deliveryOrderNumber || null,
      proof: null,
      proof_id: null,
      service: deliveryService,
      data: {
        age_control: deliveryAgeControl || null,
        cage: deliveryCageCode || null,
        // company_name: businessDeliveryType
        //   ? deliveryCompanyName
        //     ? deliveryCompanyName
        //     : ''
        //   : '',
        company_name: deliveryCompanyName || null,
        elevator: deliveryElevator || null,
        enter_code: deliveryEnterCode || null,
        use_doorbell: deliveryUseDoorBell || null,
        quiet_delivery: deliveryQuietDelivery || null,
        floor: deliveryFloorNumber || null,
        instruction: deliveryInstruction || null,
        intercom: deliveryIntercomCode || null,
        knock_on_the_door: deliveryKnockOnTheDoor || null,
        name: deliveryName,
        new_address: deliveryNewAddress || null,
        packaging_return: deliveryPackagingReturn || null,
        phone: deliveryPhoneNumber || null,
        title: nameOfService || null,
        unloading_time: deliveryUnloadingTime || null,
        waiting_time: deliveryWaitingTime || null,
        possible_proof_types: deliveryProofTypes,
        ferry_addition_cost: null,
        is_terminal: deliveryType === 'terminal',
        loading_time: null,
        on_call_courier: null,
        terminal_name: deliveryTerminalName || null,
        time_slotted_lorry: null,
        terminal_id: deliveryTerminalId || null,
        personal_number: deliveryPersonalNumber || null,
      },
    };

    const changedBooking: CreateBookingMutation = {
      freight_bill: freightBill,
      data: {
        adr,
        tempered_transport: temperedTransport,
        number: bookingNumber,
        small_addition_package_charge: smallAdditionPackageCharge,
        big_addition_package_charge: bigAdditionPackageCharge,
        extra_pallet_space: extraPalletSpace,
        flatbed_meter: flatbedMeter,
        installation: installation,
        goods_type: goodsType,
        general_parcel_type: generalParcelType,
        goods_marking: goodsMarking,
        pallet_location: palletLocation,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        img_id: packageLogo ? packageLogo : null,
        img_name: packageLogo ? img_name : null,
        img_url: packageLogo ? img_url : null,
        priority: priority === '-1' ? null : priority,
      },
    };

    await onSubmit({
      changedPickErrand,
      changedDeliveryErrand,
      changedBooking,
      changedPackages: packages as BookingPackages,
      changedShipmentStatus: shipmentStatus,
      changedWorkShiftId: workShiftId,
      pickAddress: omit(changedPickErrand.address, 'id'),
      deliveryAddress: omit(changedDeliveryErrand.address, 'id'),
    });
    await helpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={throttle(submitFormHandler, 1000)}
      validationSchema={changeScreenFormValidationSchema}
      validateOnBlur={false}
      // enableReinitialize
    >
      {(formik) => {
        const parcelsTabErrors =
          (formik?.errors?.parcels as ParcelDataFormItem[])?.reduce(
            (accum, curr) => {
              return accum + (Object.keys(curr?.data || {}).length || 0);
            },
            0,
          ) - 1 || 0;

        const isSubmit = formik.isSubmitting;

        const errorsObjectLength =
          Object.entries(formik.errors).length + parcelsTabErrors;
        const touchedObjectLength = Object.entries(formik.touched).length;
        const isShown =
          touchedObjectLength >= errorsObjectLength && errorsObjectLength > 0;

        return (
          <FormControl
            component={Form}
            sx={styles.layout}
            noValidate
            autoComplete="off"
          >
            <Typography variant="body2">{label}</Typography>
            <ChangeScreenNavigation
              pickType={formik.values.pickUpType}
              formik={formik}
              formValuesKeys={changeScreenFormValuesKeys}
              state={createScreenState}
            />
            <Box sx={styles.form}>
              <Outlet
                context={{
                  formik,
                  pickType: formik.values.pickUpType,
                  deliveryType: formik.values.deliveryType,
                  isCreateScreen,
                }}
              />
            </Box>
            <ChangeScreenMenu formik={formik} createPress={createPress} />
            <ShowSnackbar
              isShow={isShown}
              errorLength={errorsObjectLength}
              isSubmit={isSubmit}
            />
          </FormControl>
        );
      }}
    </Formik>
  );
};
