import React, { FC, useCallback, useMemo, useState } from 'react';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IconButton, SxProps, Typography } from '@mui/material';
import {
  useCompanyByIdQuery,
  useDeleteCompanyByIdMutation,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { removeSelectedCompanyId } from 'store/reducers/companySlice';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { getSelectedCompanyIdSelector } from 'store/selectors/company';
import { t } from 'ttag';

import { DialogModal } from 'components/ui/DialogModal';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface DeleteCompanyButtonProps {
  buttonStyle?: SxProps;
}

export const DeleteCompanyButton: FC<DeleteCompanyButtonProps> = React.memo(
  ({ buttonStyle = {} }) => {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const selectedCompanyId = useAppSelector(getSelectedCompanyIdSelector);
    const readOnly = useAppSelector(getIsReadonlySelector);

    const { data: companyDetail } = useCompanyByIdQuery(
      selectedCompanyId as string,
      {
        skip: !selectedCompanyId,
      },
    );

    const [deleteCompany] = useDeleteCompanyByIdMutation();

    const handleModalConfirm = useCallback(() => {
      deleteCompany(companyDetail?.id as string);
      dispatch(removeSelectedCompanyId());
    }, [companyDetail?.id]);

    const { name, city, country } = useMemo(
      () => ({
        name: companyDetail?.name || '',
        city: companyDetail?.city || '',
        country: companyDetail?.country || '',
      }),
      [companyDetail],
    );

    return (
      <>
        <LightTooltip
          title={t`You can remove company at all`}
          arrow
          placement="top"
        >
          <span>
            <IconButton
              sx={{ ...styles.button, ...buttonStyle } as SxProps}
              onClick={() => setOpenModal(true)}
              disabled={!!readOnly}
            >
              <DeleteForeverOutlinedIcon sx={{ color: 'custom.graphite' }} />
              <Typography variant="body2" ml="8px" alignSelf="flex-end">
                {t`Delete forever`}
              </Typography>
            </IconButton>
          </span>
        </LightTooltip>

        <DialogModal
          open={openModal}
          onConfirm={handleModalConfirm}
          onClose={() => setOpenModal(false)}
          title={t`Are you sure you want to delete ${name}, ${city}, ${country} Company?`}
          content={t`This action cannot be undone`}
        />
      </>
    );
  },
);
