import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  listItem: {
    width: 'initial',
    p: 0,
    mr: '42px',

    '&:first-of-type': {
      pl: 0,
    },
    '&:last-of-type': {
      pr: 0,
      mr: 0,
    },
  },

  listItemActive: {
    '& .MuiTypography-root': {
      color: 'primary.main',
    },

    '& .MuiTypography-root.MuiTypography-body2:after': {
      position: 'absolute',
      bottom: '-10px',
      left: 0,
      width: '100%',
      content: '""',
      borderTop: '2px solid',
      borderColor: 'primary.main',
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
    },

    '&:hover .MuiTypography-root': {
      color: 'primary.main',
    },
  },

  listItemError: {
    '& .MuiTypography-root': {
      color: 'custom.red',
    },

    '&:hover .MuiTypography-root': {
      color: 'custom.red',
    },
  },

  link: {
    textDecoration: 'none',
    color: 'custom.graphite',
    '&:hover .MuiTypography-root': {
      color: 'custom.grey',
      cursor: 'pointer',
    },
  },

  disabled: {
    '& .MuiTypography-root': {
      textDecoration: 'none',
      color: 'custom.grey',
      cursor: 'unset',
    },
  },

  linkTitle: {
    position: 'relative',
  },
};
