import React, { FC, useMemo } from 'react';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { IconButton } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { getSelectedCompanyIdSelector } from 'store/selectors/company';
import { t } from 'ttag';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const EditCompanyButton: FC = React.memo(() => {
  const selectedCompanyId = useAppSelector(getSelectedCompanyIdSelector);
  const readOnly = useAppSelector(getIsReadonlySelector);

  const iconStyle = useMemo(() => {
    return { color: readOnly ? 'custom.graphite' : 'primary.main' };
  }, [readOnly]);

  return (
    <LightTooltip
      title={
        selectedCompanyId
          ? t`Edit company`
          : t`Select company to get ability to edit`
      }
      arrow
      placement="top"
    >
      <span>
        <IconButton
          sx={styles.button}
          disabled={!selectedCompanyId || !!readOnly}
          // onClick={() => {}}
        >
          <QueryParamsLink route={APP_ROUTES.editCompany}>
            <BorderColorOutlinedIcon sx={iconStyle} />
          </QueryParamsLink>
        </IconButton>
      </span>
    </LightTooltip>
  );
});
