import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Button, TextField, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import {
  useNewQrCodeCompanyByIdMutation,
  useSendCompanyEmailMutation,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setToast } from 'store/reducers/settingsSlice';
import { getIsReadonlySelector } from 'store/selectors/auth';
import { t } from 'ttag';
import { SubcontractorDetail } from 'types/api';
import { getRequiredFieldValidationError } from 'utils/textLabelUtils';
import * as yup from 'yup';

import { DialogModal } from 'components/ui/DialogModal';

import { styles } from './styles';

interface AccesCompanyFormValues {
  companyEmail: string;
}
const validationSchema: yup.SchemaOf<AccesCompanyFormValues> = yup.object({
  companyEmail: yup
    .string()
    .nullable()
    .trim()
    .min(4)
    .max(50, t`Company email should not be longer than 50 symbols`)
    .required(getRequiredFieldValidationError('Company email'))
    .email(t`Value doesn't match the email address pattern`),
});

interface AccessCompanyDetailSectionProps {
  company?: SubcontractorDetail;
}

export const AccessCompanyDetailSection: FC<
  AccessCompanyDetailSectionProps
> = ({ company }) => {
  const [regenerateQrCode, { isLoading }] = useNewQrCodeCompanyByIdMutation();
  const [sendCompanyEmail, { isLoading: companyEmailLoading }] =
    useSendCompanyEmailMutation();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const readOnly = useAppSelector(getIsReadonlySelector);

  const handleRegeneratePress = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleModalConfirm = useCallback(() => {
    regenerateQrCode(company?.id as string)
      // @ts-ignore
      .then(({ error }) => {
        if (error) {
          dispatch(
            setToast({
              // @ts-ignore
              message:
                error?.error ||
                error?.data?.detail?.[0]?.msg ||
                t`Oops something happens!`,
              severity: 'error',
            }),
          );

          return;
        }
        dispatch(
          setToast({
            message: t`You have successfully regenerated QR code`,
            severity: 'success',
          }),
        );
      });
  }, [company?.id]);

  const submitFormHandler = useCallback(
    async (
      { companyEmail }: AccesCompanyFormValues,
      helpers: FormikHelpers<AccesCompanyFormValues>,
    ) => {
      helpers.setSubmitting(true);
      sendCompanyEmail({
        id: company?.id as string,
        email: companyEmail.trim(),
      })
        // @ts-ignore
        .then(({ error }) => {
          if (error) {
            dispatch(
              setToast({
                // @ts-ignore
                message:
                  error?.error ||
                  error?.data?.detail?.[0]?.msg ||
                  t`Oops something happens!`,
                severity: 'error',
              }),
            );

            return;
          }
          dispatch(
            setToast({
              message: t`You have successfully sent an email`,
              severity: 'success',
            }),
          );
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    },
    [company?.id],
  );

  const formik = useFormik<AccesCompanyFormValues>({
    initialValues: {
      companyEmail: company?.email || '',
    },
    validationSchema,
    onSubmit: submitFormHandler,
  });

  useEffect(() => {
    formik.setFieldValue('companyEmail', company?.email ? company.email : '');
  }, [company?.email]);

  const isValid = useMemo(
    () => formik.isValid,
    // && !isEmpty(formik.touched)
    [formik],
  );

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.companyDetails}>
          <Typography sx={styles.companyName}>{company?.name}</Typography>
          <Box sx={styles.cityBlock} mt="16px">
            <BusinessIcon sx={{ color: 'custom.graphite' }} />
            <Typography variant="h5" sx={{ color: 'custom.graphite' }} ml="8px">
              {company?.city}
            </Typography>
          </Box>
          <Box sx={styles.cityBlock} mt="12px">
            <LocationOnOutlinedIcon sx={{ color: 'custom.graphite' }} />
            <Typography variant="h5" sx={{ color: 'custom.graphite' }} ml="8px">
              {company?.country}
            </Typography>
          </Box>
          <Typography variant="body1" mt="24px" mb="12px">
            {t`Send a QR code for employees to log in to the application to the company's mail.`}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <TextField
            id="companyEmail"
            name="companyEmail"
            disabled={!company?.is_active || !!readOnly}
            label={t`Company email`}
            value={values.companyEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.companyEmail &&
              (Boolean(errors.companyEmail) || Boolean(errors.companyEmail))
            }
            helperText={touched.companyEmail && errors.companyEmail}
            autoComplete="new-password"
            sx={styles.input}
            fullWidth
            required
          />
          <Button
            sx={styles.submitButton}
            disabled={
              isSubmitting ||
              !isValid ||
              companyEmailLoading ||
              !company?.is_active ||
              !!readOnly
            }
            variant="contained"
            onClick={() => handleSubmit()}
          >
            {t`Send`}
          </Button>
        </Box>
        <Box sx={styles.qrWrapper}>
          <Box sx={styles.qrCodeContainer}>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={company?.qr_code || ''}
              viewBox={'0 0 256 256'}
            />
          </Box>
          <Button
            onClick={handleRegeneratePress}
            sx={styles.regenerateButton}
            variant="outlined"
            disabled={isLoading || !company?.is_active || !!readOnly}
          >
            {t`Regenerate`}
          </Button>
        </Box>
      </Box>

      <DialogModal
        open={openModal}
        onConfirm={handleModalConfirm}
        onClose={() => setOpenModal(false)}
        title={t`Are you sure you want to regenerate QR code?`}
        content={t` All devices connected to this company will be disabled until drivers scan a new regenerated QR code`}
      />
    </>
  );
};
